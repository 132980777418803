import React, {useEffect, useState, useContext} from 'react'
import queryString from 'query-string'
import {Context as AuthContext} from '../../context/AuthContext'
import { Card, CardBody, Spinner} from 'reactstrap';
import { Link } from "react-router-dom";
import justcastApi from '../../api/justcast'
import icon from '../../assets/img/integrations/kit_justcast.png'

export default function ConvertkitOauth(props) {

  const values = queryString.parse(props.location.search);
  const {state, tryLocalSignin} = useContext(AuthContext)
  const { uid, signedIn, convertKitStep } = state;

  const { redirect } = values || {}

  useEffect(() => {
    tryLocalSignin({kitRedirectUrl: redirect});
  }, [])

  useEffect(() => {
    if(uid && signedIn && redirect) {
      justcastApi.post("/v2/kit_connection_requests.json", {
        callback_url: redirect
      }).then((res) => {
        const { kit_auth_url } = res.data || {}
        if(kit_auth_url) {
          window.location = kit_auth_url
        }
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }, [uid, signedIn, redirect])

  if(!redirect) return null;

  if(state.uid && state.signedIn) {
    return <Spinner/>
  }

  if(convertKitStep) {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
        <Card>
          <CardBody
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2rem",
              padding: "3rem"
            }}
          >
            <div>
              <img src={icon} alt="Kit Connection" height={60}/>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
              <h3>Connect JustCast and Kit</h3>
              <p>We'll create private podcast feeds for your subscribers.</p>
              <p>To get started, login or signup (free).</p>
              <div>
                <Link to={'/signup'} className="btn btn-primary">Signup</Link> {" "}
                <Link to={'/signin'} className="btn btn-outline-warning">Login</Link>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  return null
}
