import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import { Toast, ToastBody, ToastHeader, Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, ModalHeader} from 'reactstrap'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as MenuContext} from '../context/MenuContext'
import justcastApi from '../api/justcast'
import StripeSvg from '../assets/img/icons/stripe.svg'
import {MailerLiteConnection, AlbyConnection, ZapierConnection, ShopifyConnection, KitConnection} from '../components/connections'
// import {getDropboxAuthUrl} from '../libs/DropboxOAuth';
import CloudDriveList from '../components/CloudDriveList'
// import {DateAgoFormatCell} from './../libs/TableCellFormat'
import {Mixpanel} from '../api/mixpanel'
// import WarningModal from '../components/WarningModal'

const ClouddriveConnections = ()  => {

  const {addWithTimeout, add} = useContext(AlertContext);
  const menuContext = useContext(MenuContext);
  const authContext = useContext(AuthContext)
  const [connections, setConnections] = useState([]);
  const [dropboxSettingModal, setDropboxSettingModal] = useState(false);
  const { stripe_connect_details_submitted } = authContext.state
  const [uploadOnly, setUploadOnly] = useState(false);
  const [dropboxConnection, setDropboxConnection] = useState(null); // TODO, change to use dropbox connection instead of connections
  const [dropboxSettingsUpdatedMessage, setDropboxSettingsUpdatedMessage] = useState(null)
  const [toastOpen, setToastOpen] = useState(false);

  //Mixpanel
  const uid = authContext.state.uid;
  const skill_stripe_tip_jar = authContext.state.skill_stripe_tip_jar;

  Mixpanel.identify(uid);
  Mixpanel.track('CloudDrive Connection page loaded');

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Connections', subtitle: "Overview", currentPageId: 'connections'});
    justcastApi.get('/v1/clouddrive_connections')
    .then((res) => {
      setConnections(res.data);
      setDropboxConnection(res.data[0].data)
      setUploadOnly(res.data[0].data?.upload_only)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  const syncConnection = (drive, driveId) => {
    // addWithTimeout({color: 'warning', message: `Syncing dropbox connection`, isOpen: true, timeout: 10000})
    const endpoint = `/v1/clouddrive_connections/${drive}/sync/${driveId}`
    justcastApi.get(endpoint)
    .then((res) => {
      console.log(res.data);
      // const email = res.data.connection.email;
      // setConnection(res.data.connection)
      addWithTimeout({color: 'secondary', message: `Connection with ${drive} has been updated!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      add({color: 'danger', message: `Error to update ${drive} - ${driveId}: Please screenshot your screen and send it to us on justcastapp@gmail.com.`, isOpen: true})
    })
  }

  const deleteConnection = (drive, driveId) => {
    const endpoint = `/v1/clouddrive_connections/${drive}/sync/${driveId}`
    justcastApi.delete(endpoint)
    .then((res) => {
      // const deletedConnection = res.data;
      const _connections = connections.map((connection) => {
        if(connection.type === drive) {
          connection['data'] = {}
          connection['active'] = false
          return connection;
        } else {
          return connection;
        }
      })
      setConnections(_connections);
      // setConnection(deletedConnection);
      addWithTimeout({color: 'secondary', message: `Connection with ${drive} has been Deleted!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      add({color: 'danger', message: `Error to delete ${drive} - ${driveId}: Please screenshot your screen and send it to us on justcastapp@gmail.com.`, isOpen: true})
    })
  }

  const handleConnectToStripe = () => {
    // this will return us an object contain url to connect to stripe
    justcastApi.post(`/v1/stripe_accounts`, {
      return_url: process.env.REACT_APP_STRIPE_CONNECT_ACCOUNT_RETURN_URL,
      refresh_url: process.env.REACT_APP_STRIPE_CONNECT_ACCOUNT_REFRESH_URL,
    })
    .then((res) => {
      const url = res.data.url;
      if(url) {
        window.location = url;
      }
    })
    .catch((err) => {
      add({color: 'danger', message: `Error to get link to connect to Stripe. Please screenshot your screen and send it to us on justcastapp@gmail.com.`, isOpen: true})
    })
  }

  const toggleDropboxModal = () => {
    setDropboxSettingModal(!dropboxSettingModal)
  }

  const handleDropboxSettingsChange = (event) => {
    const {name} = event.target
    if(name === 'hosting') {
      setUploadOnly(false)
    } else {
      setUploadOnly(true)
    }
  }

  const handleCloseModal = () => {
    setDropboxSettingModal(false)
    setUploadOnly(dropboxConnection?.upload_only)
  }

  const handleSaveDropboxSetting = () => {
    justcastApi.put(`/v1/dropbox_connections/${dropboxConnection.id}`, {...dropboxConnection, upload_only: uploadOnly})
    .then((res) => {
      setDropboxConnection(res.data)
      setUploadOnly(res.data.upload_only)
      setDropboxSettingModal(false)
      setDropboxSettingsUpdatedMessage("Updated Dropbox Settings.")
      setToastOpen(true)
    })
    .catch((err) => {
      console.log(err)
      setDropboxSettingsUpdatedMessage("Failed to update Dropbox Settings.")
      setToastOpen(true)
    })
  }

  const toastToggle = () => {
    setToastOpen(!toastOpen)
  }

  return (
    <>
      <Toast isOpen={toastOpen}>
        <ToastHeader toggle={toastToggle}>
          Dropbox Settings
        </ToastHeader>
        <ToastBody>
          {dropboxSettingsUpdatedMessage}
        </ToastBody>
      </Toast>
      <Modal isOpen={dropboxSettingModal} toggle={toggleDropboxModal}>
        <ModalHeader>
          Settings
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>Hosting with Dropbox:</strong>New files become new episodes automatically. Note: Deleting Dropbox files will delete corresponding JustCast episodes.
          </p>
          <p>
            <strong>Add from Dropbox:</strong>Handpick which Dropbox files to upload as JustCast episodes. Episodes stay intact even if Dropbox files are deleted.
          </p>
          <Form>
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="hosting"
                    checked={uploadOnly == false}
                    onChange={handleDropboxSettingsChange}
                  />{' '} Hosting with Dropbox
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="import-only"
                    checked={uploadOnly == true}
                    onChange={handleDropboxSettingsChange}
                  />{' '} Add from Dropbox
                </Label>
              </FormGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
          <Button color="primary" onClick={handleSaveDropboxSetting}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
      <div className="container-fluid">
          <div className="row">
            <CloudDriveList
              handleSync={syncConnection}
              handleDestroy={deleteConnection}
              connections={connections}
              toggleModal={toggleDropboxModal}
            />
            {/* {
              skill_stripe_tip_jar ? <div className="col-12 col-lg-6">
                <Card>
                  <CardBody className="text-center">
                    <div className="card-avatar avatar avatar-lg mx-auto">
                      <img src={StripeSvg} alt="Stripe Connection" className="avatar-img rounded"/>
                    </div>
                    <h2 className="mb-3">Stripe</h2>
                  </CardBody>
                  <CardFooter className="card-footer-boxed">
                    <Row className="align-items-center justify-content-between">
                      <div className="col-auto">
                        {stripe_connect_details_submitted ? <><span className="text-success">●</span> Connected</> : <><span className="text-secondary">●</span> Not Connect</>}
                      </div>
                      <div className="col-auto">
                        {
                          stripe_connect_details_submitted ? <Button name="setup" size="sm" color="primary" onClick={handleConnectToStripe}>Update Settings</Button>  :
                          <Button name="setup" size="sm" color="primary" onClick={handleConnectToStripe}>Setup connection</Button>
                        }
                      </div>
                    </Row>
                  </CardFooter>
                </Card>
              </div> : null
            } */}
            <div className="col-12 col-lg-6">
              <MailerLiteConnection/>
            </div>
            <div className="col-12 col-lg-6">
              <ShopifyConnection/>
            </div>
            <div className="col-12 col-lg-6">
              <KitConnection/>
            </div>
            <div className="col-12 col-lg-6">
              <ZapierConnection/>
            </div>
            <div className="col-12 col-lg-6">
              <AlbyConnection/>
            </div>
          </div>
      </div>
    </>
  )
}

export default ClouddriveConnections