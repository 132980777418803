import React, { useContext, useState} from 'react'
import {Card, CardBody, CardFooter, Row, Col, Button, ButtonGroup} from 'reactstrap'
import icon from '../../assets/img/icons/convertkit.svg'
import {Context as AuthContext} from '../../context/AuthContext'

export default function KitConnection() {
  const authContext = useContext(AuthContext);
  const { kit_accounts } = authContext.state;
  const { deleteKitAccount  } = authContext;

  const handleDelete = () => {
    const id = kit_accounts[0]?.id;
    deleteKitAccount(id)
  }

  return (
    <Card>
      <CardBody className="text-center">
        <div className="card-avatar avatar avatar-lg mx-auto">
          <img src={icon} alt="Kit Connection" className="avatar-img rounded"/>
        </div>
        <h2 className="mb-3">Kit</h2>
      </CardBody>
      <CardFooter className="card-footer-boxed">
        {kit_accounts.length === 0 &&
          <Row className="align-items-center justify-content-between">
            <div className="col-auto">
              <span className="text-secondary">●</span> Not Connect
            </div>
            <div className="col-auto">
              <a name="kit-learn-more" className="btn btn-primary btn-sm" href="https://app.kit.com/apps/705">Learn More</a> {" "}
            </div>
          </Row>
        }
        {kit_accounts.length > 0 &&
          <Row className="align-items-center justify-content-between">
            <div className="col-auto">
              <span className="text-success">●</span> Connected
            </div>
            <div className="col-auto">
              <Button name="delete" size="sm" color="secondary" onClick={handleDelete}>Delete</Button>
            </div>
          </Row>
        }
      </CardFooter>
    </Card>
  )
}
